import 'swiper/css'
import 'swiper/css/navigation'
import 'react-tooltip/dist/react-tooltip.css'

import '../styles/index.css'

import { ApolloProvider } from '@apollo/client/react'
import * as snippet from '@segment/snippet'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import * as React from 'react'

import Layout from '../components/Layouts/Layout'
import { client } from '../graphql/apollo-client'
import { trackPage } from '../tracking'
import { substoreSettings } from '../utils/substores.config'
import Head from 'next/head'

gsap.registerPlugin(ScrollTrigger)

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter()
  if (router.pathname === '/be')
    return (
      <div className="w-full h-[100vh]">
        <Component />
      </div>
    )

  const lighten = (color: string, percent: number) => {
    const amt = Math.round(2.55 * percent)
    const R = parseInt(color.substring(1, 3), 16) + amt
    const G = parseInt(color.substring(3, 5), 16) + amt
    const B = parseInt(color.substring(5, 7), 16) + amt
    return `#${((1 << 24) + (R << 16) + (G << 8) + B).toString(16).slice(1).toUpperCase()}`
  }

  const darken = (color: string, percent: number) => {
    const amt = Math.round(2.55 * percent)
    const R = Math.max(0, parseInt(color.substring(1, 3), 16) - amt)
    const G = Math.max(0, parseInt(color.substring(3, 5), 16) - amt)
    const B = Math.max(0, parseInt(color.substring(5, 7), 16) - amt)
    return `#${((1 << 24) + (R << 16) + (G << 8) + B).toString(16).slice(1).toUpperCase()}`
  }

  ;(() => {
    if (substoreSettings) {
      const primaryColor = substoreSettings.primaryColor
      const secondaryColor = substoreSettings.secondaryColor
      if (typeof document === 'undefined') return
      const root = document.querySelector(':root') as HTMLElement
      root.style.setProperty('--primary-50', lighten(primaryColor, 50))
      root.style.setProperty('--primary-100', lighten(primaryColor, 40))
      root.style.setProperty('--primary-200', lighten(primaryColor, 30))
      root.style.setProperty('--primary-300', lighten(primaryColor, 20))
      root.style.setProperty('--primary-400', lighten(primaryColor, 10))
      root.style.setProperty('--primary-500', lighten(primaryColor, 0))
      root.style.setProperty('--primary-600', darken(primaryColor, 10))
      root.style.setProperty('--primary-700', darken(primaryColor, 20))
      root.style.setProperty('--primary-800', darken(primaryColor, 30))
      root.style.setProperty('--primary-900', darken(primaryColor, 40))

      root.style.setProperty('--secondary-50', lighten(secondaryColor, 50))
      root.style.setProperty('--secondary-100', lighten(secondaryColor, 40))
      root.style.setProperty('--secondary-200', lighten(secondaryColor, 30))
      root.style.setProperty('--secondary-300', lighten(secondaryColor, 20))
      root.style.setProperty('--secondary-400', lighten(secondaryColor, 10))
      root.style.setProperty('--secondary-500', darken(secondaryColor, 10))
      root.style.setProperty('--secondary-600', darken(secondaryColor, 10))
      root.style.setProperty('--secondary-700', darken(secondaryColor, 20))
      root.style.setProperty('--secondary-800', darken(secondaryColor, 30))
      root.style.setProperty('--secondary-900', darken(secondaryColor, 40))

      root.style.setProperty('--contrast', substoreSettings.contrastColor)
    }
  })()

  const getLayout = Component.getLayout ?? (page => page)

  const loadSegment = () => {
    const options = {
      apiKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
      // page: true,
    }
    if (process.env.NEXT_PUBLIC_NODE_ENV) {
      return snippet.max(options)
    } else {
      return snippet.min(options)
    }
  }

  React.useEffect(() => {
    if (router.asPath) trackPage()
  }, [router.asPath])

  const gtmId = substoreSettings?.gtmId ?? 'GTM-TMNNKKB'

  return (
    <ApolloProvider client={client}>
      {substoreSettings && (
        <Head>
          <link rel="icon" href={substoreSettings.faviconPath} sizes="any" />
        </Head>
      )}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />

      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId}');`,
        }}
      ></script>

      <Script dangerouslySetInnerHTML={{ __html: loadSegment() }} id="segmentScript" />

      <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
    </ApolloProvider>
  )
}

export default MyApp
