import { gql } from '@apollo/client'

const PUBLIC_SIGN_UP_NEWSLETTER = gql`
  mutation PublicSignUpNewsletter($email: String!, $newsletterListId: String!) {
    publicSignUpNewsletter(email: $email, newsletterListId: $newsletterListId) {
      email
      substore_source
    }
  }
`

const USER_SIGN_OUT_NEWSLETTER = gql`
  mutation UserSignOutNewsletter {
    userSignOutNewsletter {
      id
      receivesNewsletter
    }
  }
`

export { PUBLIC_SIGN_UP_NEWSLETTER, USER_SIGN_OUT_NEWSLETTER }
