import create from 'zustand'
import { devtools } from 'zustand/middleware'

import { isDevEnvironment } from '../../utils/utils'
import { Profile } from './auth.types'

export interface AuthState {
  whoami?: any
  profile?: Profile

  setWhoami: (payload: any) => void
  getProfile: (payload: Profile) => void
}

const useAuthStore = create<AuthState>()(
  devtools(
    (set, get) => ({
      // State
      whoami: undefined,
      profile: undefined,

      // Actions
      setWhoami: payload => set({ whoami: payload }),

      getProfile: payload => {
        // prettier-ignore
        set({ profile: payload })
      },
    }),
    {
      name: 'justdrive-store-auth',
      enabled: isDevEnvironment(),
    }
  )
)

export default useAuthStore
