import { useMutation } from '@apollo/client'
import * as React from 'react'

import { PUBLIC_SIGN_UP_NEWSLETTER } from '../graphql/mutations'
import { identify, identifySentry, track } from '../tracking'
import { getBrandName, newsLetterListId } from '../utils/substores.config'

type Props = {}

const NewsletterForm = (props: Props) => {
  const [submitted, setSubmitted] = React.useState(false)

  const email: undefined | React.Ref<HTMLInputElement> = React.useRef()

  // ---

  const [signUpNewsletter, { error, loading }] = useMutation(PUBLIC_SIGN_UP_NEWSLETTER)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setSubmitted(false)

    signUpNewsletter({
      variables: {
        email: email.current.value,
        newsletterListId: newsLetterListId,
      },
    })
      .then(resp => {
        setSubmitted(true)
        identify({ email: email.current?.value })
        track('NEWSLETTER_SUBSCRIBED', { email: email.current.value })
        identifySentry({ email: email.current?.value })
        email.current.value = ''
      })
      .catch(err => {
        console.error(err)
        setSubmitted(true)
        email.current.value = ''
      })
  }

  // ---

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <fieldset>
          <div>
            <div className="flex items-center border-t border-gray-400 last-of-type:border-b">
              <input ref={email} type="email" className="border-none w-full px-0 py-6" placeholder="Email adresse *" required />
            </div>
          </div>

          <div className="md:flex justify-between items-center mt-14">
            <div className="">
              <label className="flex mb-4">
                <input type="checkbox" required />
                <p className="inline-block text-xs leading-4 ml-2">
                  Jeg accepterer at blive kontaktet med information/nyheder via elektroniske medier
                </p>
              </label>

              <label className="flex">
                <input type="checkbox" required />
                <p className="inline-block text-xs leading-4 ml-2">
                  Jeg accepterer at mine data behandles i overensstemmelse med {getBrandName('Justdrive')} privatlivspolitik
                </p>
              </label>
            </div>

            <button
              type="submit"
              className="flex items-center justify-center bg-green-500 hover:bg-green-600 transition-colors text-contrast py-3 md:py-1 px-8 rounded-full w-full md:w-auto mt-10 md:mt-0"
            >
              Tilmeld
              {loading && (
                <svg
                  className="animate-spin ml-2 h-4 w-4 text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </button>
          </div>

          {submitted && !error ? <p className="text-green-800 mt-4">Du er nu tilmeldt! Hold øje med din indbakke.</p> : null}
          {error ? <p className="text-red-500 mt-4">{error.message}</p> : null}
        </fieldset>
      </form>
    </div>
  )
}

export default NewsletterForm
