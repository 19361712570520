import * as Sentry from '@sentry/nextjs'
import Cookies from 'js-cookie'
import { client } from './graphql/apollo-client'
import { WHO_AM_I } from './graphql/queries'

export const TRACK = {
  NEWSLETTER_SUBSCRIBED: 'Newsletter Subscribed',
  SEE_CARS_BUTTON_CLICKED: 'See Cars Button Clicked',
  DAMAGES_ACCORDION_TOGGLED: 'Damages Accordion Toggled',
  FOOTER_ACCORDION_TOGGLED: 'Footer Accordion Toggled',
  LISTING_PAGE_VIEWED: 'Listing Page Viewed',

  // AUTH
  USER_LOGGED_IN: 'User Logged In',
  USER_LOGGED_OUT: 'User Logged Out',
  USER_SIGNED_UP: 'User Signed Up',
  USER_CREATED_CUSTOMER: 'User Created Customer',

  // CHECKOUT
  CHECKOUT_START: '[CHECKOUT] Start',
  CHECKOUT_LINK_DONE: '[CHECKOUT] Link Done',
  CHECKOUT_PICKUP_DONE: '[CHECKOUT] Pickup Done',
  CHECKOUT_RETURN_DONE: '[CHECKOUT] Return Done',
  CHECKOUT_ADDON_DONE: '[CHECKOUT] Addon Done',
  CHECKOUT_QUESTIONS_DONE: '[CHECKOUT] Questions Done',
  CHECKOUT_CONFIRM: '[CHECKOUT] Confirm',
  CHECKOUT_PAYMENT_CONFIRM: '[CHECKOUT] Payment Confirm',
  CHECKOUT_PAYMENT_DONE: '[CHECKOUT] Payment Done',
  CHECKOUT_PAYMENT_FAILED: '[CHECKOUT] Payment Failed',
  CHECKOUT_CREDIT_SCORE_FAILED: '[CHECKOUT] Credit Score Failed',
  CHECKOUT_CREDIT_SCORE_RESUBMITTED: '[CHECKOUT] Credit Score Resubmitted',
  CHECKOUT_CREDIT_SCORE_DECLINED: '[CHECKOUT] Credit Score Declined',
  CHECKOUT_SSN_FAILED: '[CHECKOUT] SSN Failed',
  CHECKOUT_SSN_RESUBMITTED: '[CHECKOUT] SSN Resubmitted',
  CHECKOUT_DECLINED: '[CHECKOUT] Declined',
  CHECKOUT_CONTRACT_SIGNED: '[CHECKOUT] Contract Signed',
  CHECKOUT_SUCCESS: '[CHECKOUT] Success',
}

type TrackType = keyof typeof TRACK

type UserTraits = {
  id?: number | string
  email?: string

  phone?: string | null
  firstName?: string | null
  lastName?: string | null
  birthday?: string | null
  city?: string | null
  state?: string | null
  postalCode?: string | null
  country?: string | null
}

export const trackPage = (pageName?: string, properties: any = {}) => {
  if (!properties) properties = {}
  const fbc = Cookies.get('_fbc')
  const fbp = Cookies.get('_fbp')

  if (fbc) properties.fbc = fbc
  if (fbp) properties.fbp = fbp
  if (typeof window !== undefined) properties.substore_origin = window.location.origin

  if (process.env.NODE_ENV === 'production') {
    // @ts-ignore
    window?.analytics?.page(pageName, properties)
  }
}

export const track = (EVENT: TrackType, properties?: any) => {
  if (!properties) properties = {}
  if (process.env.NODE_ENV === 'production') {
    if (typeof window !== undefined) properties.substore_origin = window.location.origin

    // @ts-ignore
    window?.analytics?.track(TRACK[EVENT], properties)
  }
}

export const trackCustom = (EVENT: string, properties: any = {}) => {
  if (!properties) properties = {}
  const fbc = Cookies.get('_fbc')
  const fbp = Cookies.get('_fbp')

  if (fbc) properties.fbc = fbc
  if (fbp) properties.fbp = fbp
  if (typeof window !== undefined) properties.substore_origin = window.location.origin
  if (process.env.NODE_ENV === 'production') {
    // @ts-ignore
    window?.analytics?.track(EVENT, properties)
  }
}

export const identify = async (traits: UserTraits) => {
  if (process.env.NODE_ENV === 'production') {
    const whoami = await client.query({
      query: WHO_AM_I,
    })

    const address = whoami?.data?.whomai?.customer?.address || whoami?.data?.whoami?.customer?.addresses[0] || null
    const customer = whoami?.data?.whoami?.customer || whoami?.data?.whoami?.customers[0] || null
    const birthdate = customer?.birthday ? new Date(customer?.birthday) : null
    const birthday = birthdate ? birthdate.toISOString().split('T')[0].replaceAll('-', '') : null

    // @ts-ignore
    window?.analytics?.identify(traits.id, {
      email: traits.email,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      phone: customer?.phone,
      birthday: birthday,
      address: {
        city: address?.city,
        state: address?.state,
        postalCode: address?.postalCode,
        country: address?.country,
      },
    })
  }
}

export const identifySentry = (traits: UserTraits) => {
  Sentry.setUser({ email: traits.email })
}
