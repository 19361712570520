import { Substore } from '../utils/substores.config'
import nissanFull from '../public/substores/nissan/logosmall.png'
import nissanSmall from '../public/substores/nissan/Nissan_2020_logo.svg'

type Props = {
  subdomain: string
  logoType: 'minimal' | 'full'
}

// Full logo aspect ratio: h: 1 w: 6.7
const sizeStyleFull = 'w-[174px] h-[26px]'

// Minimal logo aspect ratio: h: 1 w: 1.15
const sizeStyleMinimal = 'w-[165px] h-[144px]'

export const SubstoresLogo: React.FC<Props> = ({ subdomain, logoType }) => {
  switch (subdomain as keyof Substore | null) {
    // case 'karvil':
    //   return logoType === 'full' ? (
    //     <img className={sizeStyleFull} src="https://picsum.photos/id/10/200/30"></img>
    //   ) : logoType === 'minimal' ? (
    //     <img className={sizeStyleMinimal} src="https://picsum.photos/id/10/200/200"></img>
    //   ) : null

    // case 'andersen-biler':
    //   return logoType === 'full' ? (
    //     <img className={sizeStyleFull} src="https://picsum.photos/id/24/200/30"></img>
    //   ) : logoType === 'minimal' ? (
    //     <img className={sizeStyleMinimal} src="https://picsum.photos/id/24/200/200"></img>
    //   ) : null

    case 'nissan-danmark':
      return logoType === 'full' ? (
        <img className={'w-[165px] -mt-8 md:-mt-6 object-cover invert mix-blend-difference'} src={nissanFull.src}></img>
      ) : // <img className={sizeStyleMinimal} src={nissanSmall.src}></img>
      logoType === 'minimal' ? (
        <img className={sizeStyleMinimal} src={nissanSmall.src}></img>
      ) : null

    case null:
      return <></>
  }
  return <></>
}
